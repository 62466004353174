<template>
    <Layout>
    <template #content>
        <el-result
            icon="warning"
            title="Warning"
            sub-title="User is not activated, please contact admin for verification."
        />
    </template>
    </Layout>
</template>



<script>
import Layout from "@components/Layout.vue"

export default{
    name : "Inactive",
    components : { Layout },

}

</script>



<style>
</style>
